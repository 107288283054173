import {
  useContext,
  createContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

const clientContext = createContext(false);

const Provider = clientContext.Provider;

type IsClientProviderProps = {
  children?: ReactNode;
};

export const IsClientProvider = (props: IsClientProviderProps) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return <Provider value={isClient}>{props.children}</Provider>;
};

export const useIsClient = () => useContext(clientContext);
