import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import Script from "next/script";
import React, { Suspense } from "react";
import { IntlProvider } from "react-intl";

import "../styles/globals.css";
import { useTrackPageviews } from "../lib/analytics/gtag";
import { RelayProvider } from "../lib/relay/provider";
import { IsClientProvider } from "../lib/rendering/client-context";

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

function MyApp(props: AppProps) {
  const {
    Component,
    pageProps: { session, ...pageProps },
  } = props;

  useTrackPageviews();

  return (
    <>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}');
        `}
      </Script>

      <SessionProvider session={session}>
        <RelayProvider pageProps={pageProps}>
          <IntlProvider
            // messages={messagesInFrench}
            locale="en-AU"
            defaultLocale="en-AU"
          >
            <Suspense fallback={<div>Loading...</div>}>
              <IsClientProvider>
                <Component {...pageProps} />
              </IsClientProvider>
            </Suspense>
          </IntlProvider>
        </RelayProvider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
