import React, { memo, ReactNode, useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";

import { useEnvironment } from "./environment";

type RelayProviderProps = {
  children: ReactNode;
  pageProps?: any;
};

export const RelayProvider = function RelayProvider(props: RelayProviderProps) {
  // const session = useSession();

  // const token = session.data?.accessToken;
  const environment = useEnvironment(props.pageProps.initialRecords);

  return (
    <RelayEnvironmentProvider environment={environment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};
