import { Session } from "next-auth";
import { getSession } from "next-auth/react";
import { useMemo } from "react";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { FetchFunction } from "relay-runtime/lib/network/RelayNetworkTypes";
import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes";

const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string;

let relayEnvironment: Environment | undefined;
let session: Session | undefined | null;

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise
const fetchQuery: FetchFunction = async (
  operation,
  variables,
  cacheConfig,
  uploadables
) => {
  session = session ?? (await getSession());

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(typeof session?.accessToken === "string"
        ? { Authorization: `Bearer ${session.accessToken}` }
        : {}),
    }, // Add authentication and other headers here
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  return response.json();
};

function createEnvironment(initialRecords?: RecordMap) {
  return new Environment({
    // Create a network layer from the fetch function
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource(initialRecords)),
    isServer: typeof window === "undefined",
  });
}

export function initEnvironment(initialRecords?: RecordMap) {
  // Create a network layer from the fetch function
  const environment = relayEnvironment ?? createEnvironment(initialRecords);

  // If your page has Next.js data fetching methods that use Relay, the initial records
  // will get hydrated here
  if (initialRecords) {
    environment.getStore().publish(new RecordSource(initialRecords));
  }

  // For SSG and SSR always create a new Relay environment
  if (typeof window === "undefined") return environment;

  // Create the Relay environment once in the client
  if (!relayEnvironment) relayEnvironment = environment;

  return relayEnvironment;
}

export function useEnvironment(initialRecords?: RecordMap) {
  const store = useMemo(
    () => initEnvironment(initialRecords),
    [initialRecords]
  );
  return store;
}
